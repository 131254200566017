import React, { useEffect, useState } from "react";
import "./Appbar.css";
import { jwtDecode } from "jwt-decode";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import axios from "axios";

const Appbar = () => {
  const [balance, setBalance] = useState(0.0);

  // Function to get userId from JWT token
  const getUserIdFromToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode the token
        return decodedToken?.userId; // Return the userId from the decoded token
      } catch (error) {
        console.error("Invalid token:", error); // Catch and log any errors in decoding
      }
    }
    return null; // If no token exists
  };

  const userId = getUserIdFromToken();

  // Function to fetch wallet balance
  const fetchWalletBalance = async () => {
    try {
      const response = await axios.get(
        `http://localhost:8080/api/get-wallet-balance/${userId}`
      );

      if (response.status === 200) {
        setBalance(response.data.mainBalance);
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  // Fetch wallet balance on component mount
  useEffect(() => {
    fetchWalletBalance();
  }, []);

  return (
    <nav className="navbar sticky-top navbar-expand-lg">
      <div className="container-fluid">
        <Link className="navbar-brand mx-3" to="/">
          <img src={Logo} width="70" alt="Logo" />
        </Link>
        <div className="d-flex align-items-between">
          <div className="mx-2">
            <Link to="/wallets" className="text-dark">
              <AccountBalanceWalletOutlinedIcon />
            </Link>
            <small className="fw-bold mx-1">{balance || 0} USD</small>
          </div>
          <div>
            <LanguageIcon />
            <small className="mx-1">EN</small>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Appbar;
