import React, { useEffect, useState } from "react";
import "./Style.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Appbar from "../../components/Appbar/Appbar";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import { ArrowBackOutlined, ContentCopy } from "@mui/icons-material";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TextField } from "@mui/material";
import getUserId from "../Functions/getUserId";

const DepositPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract depositId (d_key) and accountId (account_key) from the URL query params
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };

  const accountId = getQueryParams("account");
  const depositId = getQueryParams("deposit_method");

  const [depositMethod, setDepositMethod] = useState(null);
  const [depositAddress, setDepositAddress] = useState("");
  const [txId, setTxId] = useState(""); // State to store TX ID
  const [amount, setAmount] = useState(""); // State to store amount

  // Fetch the single deposit method based on depositId
  useEffect(() => {
    const fetchDepositMethod = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/deposit-methods-key?depositId=${depositId}`
        );
        const method = response.data;
        setDepositMethod(method);
        setDepositAddress(method.address || method.wallet_address || "");
      } catch (error) {
        console.error("Error fetching deposit method:", error);
      }
    };

    if (depositId) {
      fetchDepositMethod();
    }
  }, [depositId]);

  // Copy address to clipboard
  const handleCopyAddress = () => {
    if (depositAddress) {
      navigator.clipboard.writeText(depositAddress);
      toast.success("Address copied to clipboard!");
    }
  };

  // Handle TX ID input change
  const handleTxIdChange = (e) => {
    setTxId(e.target.value);
  };

  // Handle Amount input change
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (!txId || !amount) {
      toast.error("Please enter both TX ID and Amount before continuing.");
      return;
    }

    // Submit the form data
    try {
      const response = await axios.post(
        "http://localhost:8080/api/submit-deposit",
        {
          amount: amount, // Use the amount from the input
          userId: getUserId(), // User ID from query params or session
          account_id: accountId,
          tx_id: txId,
          methodId: depositId, // Method ID (this should be linked to the deposit method)
        }
      );
      toast.success("Deposit submitted successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error submitting deposit:", error);
      toast.error("There was an error submitting the deposit.");
    }
  };

  return (
    <>
      <Sidebar />
      <Appbar />
      <section className="deposit container">
        <h2 className="fw-bold">Deposit</h2>
        <Link
          to={`/confirm-deposit?account=${accountId}&deposit_method=${depositId}`}
          style={{ fontSize: "15px" }}
          className="text-decoration-none"
        >
          <ArrowBackOutlined className="mx-1" /> Back
        </Link>

        {/* Deposit Method Details */}
        <div className="methods">
          {depositMethod ? (
            <div className="row d-flex justify-content-between">
              <div className="col-xl-6">
                {/* Display Deposit Method */}
                <div style={{ fontSize: "12px" }} className="mt-3">
                  Payment Method
                </div>
                <select className="form-control" disabled>
                  <option value={depositMethod.d_key}>
                    {depositMethod.d_name} ({depositMethod.d_network})
                  </option>
                </select>
                <div
                  style={{ fontSize: "13px" }}
                  className="alert alert-warning mt-3"
                >
                  <strong>Warning!</strong> We will only accept for this deposit{" "}
                  {depositMethod.d_name} on{" "}
                  <span className="fw-bold text-dark">
                    {depositMethod.d_network} Network
                  </span>
                </div>

                {/* Address Display */}
                <div className="mt-3">
                  <p style={{ fontSize: "14px" }}>
                    To deposit funds, make a transfer to the blockchain address
                    below:
                    <br />
                    <br />
                    Your unique blockchain address:
                    <br />
                    <span className="fw-bold">
                      {depositAddress ? depositAddress : "Address not found"}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-start mt-1">
                  <button
                    className="btn btn-theme btn-y mt-0"
                    onClick={handleCopyAddress}
                  >
                    <ContentCopy className="mx-1" /> Copy Address
                  </button>
                  <Link
                    className="btn btn-theme btn-g mx-3 mt-0"
                    to="/my-accounts"
                  >
                    Go to My Accounts
                  </Link>
                </div>
                {/* QR Code Display */}
                <div className="mt-3">
                  {depositAddress && (
                    <QRCode value={depositAddress} size={150} />
                  )}
                </div>

                {/* TX ID Input and Submit */}
                <div className="d-flex justify-content-start mt-3">
                  <TextField
                    type="text"
                    className="form-control"
                    placeholder="Enter TX ID"
                    value={txId}
                    onChange={handleTxIdChange}
                    fullWidth
                  />
                </div>
                <div className="d-flex justify-content-start mt-3">
                  <TextField
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={handleAmountChange}
                    fullWidth
                  />
                </div>
                <div>
                  <button
                    className="btn btn-theme btn-y w-100 mt-3"
                    onClick={handleSubmit}
                  >
                    Confirm Payment
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p>No deposit method found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default DepositPayment;
