import React, { useEffect, useState } from "react";
import "./Style.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Appbar from "../../components/Appbar/Appbar";
import { Link, useLocation } from "react-router-dom";

const Withdraw = () => {
  const location = useLocation();
  // Extract account ID from URL query params
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };

  const accountId = getQueryParams("account");
  const [depositMethods, setDepositMethods] = useState([]);

  // Fetch deposit methods when the component mounts
  useEffect(() => {
    const fetchDepositMethods = async () => {
      try {
        const response = await fetch(
          "http://localhost:8080/api/deposit-methods"
        );
        const data = await response.json();
        setDepositMethods(data);
      } catch (error) {
        console.error("Error fetching deposit methods:", error);
      }
    };
    fetchDepositMethods();
  }, []);

  return (
    <>
      <Sidebar />
      <Appbar />
      <section className="deposit container">
        <h2 className="fw-bold">Withdrawal</h2>
        <h4 className="fw-bold">All Payment Methods</h4>
        {/* deposit methods */}
        <div className="methods">
          <div className="row d-flex justify-content-between">
            {depositMethods.map((method) => (
              <div key={method.d_name} className="card col-xl-6 col-12">
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/confirm-deposit?account=${accountId}&deposit_method=${method.d_key}`}
                  className="text-dark"
                >
                  <div className="row">
                    <div className="col-3">
                      {" "}
                      <img
                        src={method.logo}
                        alt={method.d_name}
                        className="card-logo"
                      />
                    </div>
                    <div className="col-6">
                      {" "}
                      <h3>{method.d_name}</h3>
                      <p>
                        Processing Time: {method.processing_time}
                        <br />
                        Fee: {method.fee}
                        <br />
                        Limits: {method.limits}
                      </p>
                    </div>
                    <div className="col-3">
                      <span className="cap-g">Recommended</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Withdraw;
